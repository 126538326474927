import React, { useContext, useMemo } from "react";
import { Table } from "react-bootstrap";
import FrontEndContext from "../../context/FrontEndContext";

export interface PlantingSummaryParams {
  plantingId: string;
}

export interface PlantingSummaryData {
  name: string;
  "% Ideal": number;
  "Wheel Number": number;
  Misses: number;
  Doubles: number;
  "Not Ideal": number;
  Ideal: number;
  "Ideal%": number;
}

const roundToTwo = (x: number) => {
  return Math.round((x + Number.EPSILON) * 100) / 100;
};

const formatNumber = (x: number) => {
  return x.toLocaleString();
};

const formatValue = (x: number) => {
  return formatNumber(roundToTwo(x));
};

const safeNumber = (value: any): number => {
  if (typeof value === 'number') return value;
  if (typeof value === 'string') {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  }
  return 0;
};

interface PlantingSummaryTableProps {
  wheelsInfo: PlantingSummaryData[];
}

export default function PlantingSummaryTable(props: PlantingSummaryTableProps) {
  const { wheelsInfo } = props;
  const { t } = useContext(FrontEndContext);

  const wheelNumbers = useMemo(() => wheelsInfo.map((_value, index) => index + 1), [wheelsInfo]);

  const rowTypes = useMemo(() => [
    { key: "Misses", label: "misses" },
    { key: "Doubles", label: "doubles" },
    { key: "Not Ideal", label: "notIdeal" },
    { key: "Ideal", label: "ideal" },
    { key: "Ideal%", label: "percentIdeal" }
  ], []);

  const tableRows = useMemo(() => {
    return rowTypes.map((rowType) => {
      const rowLabel: string = t(rowType.label);
      const rowKey: string = rowType.key;
      let total = 0;

      const cells = wheelNumbers.map((wheelNumber) => {
        const wheelIndex = wheelNumber - 1;
        const wheelInfo = wheelsInfo[wheelIndex];
        if (wheelInfo == null) return null;
        const rowValue = safeNumber(wheelInfo[rowKey as keyof PlantingSummaryData]);
        total += rowValue;
        return <td key={`row-${rowKey}-${wheelNumber}`}>{formatValue(rowValue) || "-"}</td>;
      });

      if (rowKey === "Ideal%") {
        total /= wheelNumbers.length;
      }

      return (
        <tr key={`row-${rowKey}`}>
          <td key={`row-${rowKey}-label`}>{rowLabel}</td>
          {cells}
          <td key={`row-${rowKey}-total`}>{formatValue(total) || "-"}</td>
        </tr>
      );
    });
  }, [rowTypes, wheelNumbers, wheelsInfo, t]);

  return (
    <Table striped bordered hover size="sm" className="mb-0">
      <thead>
        <tr key={`header-row`}>
          <th key={`header-row-title`}>{t("rowsNumber")}</th>
          {wheelNumbers.map((wheelNumber) => (
            <th key={`header-row-wheel-${wheelNumber}`}>{wheelNumber}</th>
          ))}
          <th>{t("total")}</th>
        </tr>
      </thead>
      <tbody>
        {tableRows}
      </tbody>
    </Table>
  );
}