import { useContext, useEffect, useMemo, useState } from "react";
import {  useNavigate } from "react-router-dom";
import MUIDataTable, { FilterType, MUIDataTableColumn, Responsive, SelectableRows } from "mui-datatables";
import { Button } from "@mui/material";
import { formatTime } from "../../utils";
import { DisplayablePlantingData } from "../../config";
import FrontEndContext from "../../context/FrontEndContext";
import "./index.css";

const CONFIG_STORAGE_KEY = "active-planting";

interface LiveViewListProps {
  dataLoaded: boolean;
  activePlantings?: DisplayablePlantingData[];
}

export default function LiveViewList(props: LiveViewListProps) {
  const { dataLoaded, activePlantings } = props;
  const navigate = useNavigate();
  const data = (dataLoaded && activePlantings) || [];
  const { t, language } = useContext(FrontEndContext);

const clearLocalStorageConfig = () => {
    localStorage.removeItem(CONFIG_STORAGE_KEY);
  };

useEffect(() => {
    clearLocalStorageConfig();
  }, [language]);

const COLUMNS = useMemo<MUIDataTableColumn[]>(() => {
  const infoOptions = {
    setCellProps: () => ({
      align: "center",
    }),
  };

  const viewOptions = {
    filter: false,
    viewColumns: false,
    customBodyRender: (value: any) => (
      <Button variant="contained" onClick={() => navigate(`/live/${value}`)}>
        {t("live")}
      </Button>
    ),
  };

  const dateOptions = {
    customBodyRender: (value: any) => formatTime(value),
    setCellProps: () => ({
      align: "center",
    }),
  };

  return [
    { label: " ", name: "systemId", options: viewOptions },
    { label: t("company").toUpperCase(), name: "companyName", options: { ...infoOptions, display: false } },
    { label: t("system").toUpperCase(), name: "systemName", options: infoOptions },
    { label: t("farm").toUpperCase(), name: "farm", options: infoOptions },
    { label: t("field").toUpperCase(), name: "field", options: infoOptions },
    { label: t("variety").toUpperCase(), name: "variety", options: infoOptions },
    { label: t("seedlot").toUpperCase(), name: "seedlot", options: infoOptions },
    { label: t("acreage").toUpperCase(), name: "acreage", options: infoOptions },
    { label: t("seedSpacing").toUpperCase(), name: "seedSpacing", options: infoOptions },
    { label: t("seedWeight").toUpperCase(), name: "seedWeight", options: infoOptions },
    { label: t("lastUpdated").toUpperCase(), name: "updateTime", options: dateOptions },
  ];
}, [t, navigate])

  const OPTIONS = {
    download: false,
    filterType: "dropdown" as FilterType,
    pagination: false,
    print: false,
    responsive: "standard" as Responsive,
    selectableRows: "single" as SelectableRows,
    selectableRowsHideCheckboxes: true,
    storageKey: CONFIG_STORAGE_KEY,
    textLabels: {
      body: {
        noMatch: dataLoaded ? t("noActivePlantings") : t("loading"),
      },
      pagination: {
        next: t("nextPage"),
        previous: t("previousPage"),
        rowsPerPage: t("rowsPerPage"),
        displayRows: t("displayRows"),
      },
      toolbar: {
        search: t("searchTable"),
        downloadCsv: t("downloadCsv"),
        viewColumns: t("viewColumns"),
        filterTable: t("filterTable"),
      },
      filter: {
        all: t("tableFilterAll"),
        title: t("tableFilterTitle"),
        reset: t("tableFilterReset"),
      },
      viewColumns: {
        title: t("viewColumnsTitle"),
        titleAria: t("viewColumnsTitleAria"),
      },
    },
  };

  return (
    <div className="field-log-table">
      <MUIDataTable title={t("activePlantings")} data={data} columns={COLUMNS} options={OPTIONS} />
    </div>
  );
}