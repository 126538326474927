import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import FrontEndContext from "../../context/FrontEndContext";
import Page from "../../components/Page";
import "./index.css";
import config, { FrontEndData, SystemStatusData, WheelLiveCountsData, WheelLiveCountsMessage } from "../../config";
import { ScaleComponent } from "../../components/ScaleComponent";
import CardComponent from "../../components/CardComponent";
import { ChartColumnDataComponent, ChartColumnDataTitlesComponent } from "../../components/ChartColumnDataComponent";
import { ChartColumnTopComponent } from "../../components/ChartColumnTopComponent";
import { ChartColumnBarComponent } from "../../components/ChartColumnBarComponent";

const { getSystemStatusURL, getLiveCountsURL, getFrontEndStateURL } = config?.api;

const HEADER_NAME = "";

const TOP_ROW_HEIGHT_SCREEN_PERCENTAGE = 11;
const DATA_ROW_HEIGHT_SCREEN_PERCENTAGE = 5.5;

const BAR_CHART_PERCENTAGE_WARNING_RANGE = { above: 5, below: 5 };
const BAR_CHART_MIN_BAR_HEIGHT_PERCENTAGE = 8;
const MIN_DISPLAYED_BAR_PERCENTAGE = 70;
const NUM_DISPLAYED_BAR_TICKS_INCLUSIVE = 7;

//DM February 1 updated to same structure and operator-client
interface DataRowInfo {
  prop: string;
  checked: boolean;
  primary: string;
  showPercent: boolean;
  showToggleSwitch: boolean;
}



const gpsSpeedHeader = "GPS Speed";
const averageSpacingHeader = "Avg. Spacing";
const targetSpacingHeader = "Target Spacing";
const cwtPerAcreHeader = "Cwt/ac";
const soilTemperature = "Temperature \xB0F";//DM added Jan 19 2024
const acresHeader = "Acres";

const sliderValue = -1;

interface HomePageServerData {
  systemStatus: SystemStatusData | undefined;
  wheelsLiveCounts: WheelLiveCountsData[] | WheelLiveCountsMessage | undefined;
  frontEndState: FrontEndData | undefined;//DM February 1. Use frontEndSate from tractor
}

const Live = () => {
  const { systemId } = useParams();
  const { headersWithAuth, isAuthenticating, isAuthenticated, t } = React.useContext(FrontEndContext);
  //DM Sept 12 2024. Language translation
  const DEFAULT_FIRST_DATA_ROWS_INFO: DataRowInfo[] = [
    { prop: "speed", checked: true, primary: t("liveSpeed"), showPercent: false, showToggleSwitch: false },
    { prop: "misses", checked: true, primary: "Misses", showPercent: false, showToggleSwitch: true },
    { prop: "doubles", checked: true, primary: "Doubles", showPercent: false, showToggleSwitch: true },
    { prop: "notIdeal", checked: true, primary: "Not Ideal", showPercent: false, showToggleSwitch: true },
    { prop: "ideal", checked: true, primary: "Ideal", showPercent: false, showToggleSwitch: true },
    { prop: "wheelAverageSpacing", checked: true, primary: "Avg Spacing", showPercent: false, showToggleSwitch: false },
  ];
  const navigate = useNavigate();
  

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate("/login");
    }
  });

  const { data: homePageServerData } = useQuery<HomePageServerData>(
    "Retrieve Live Counts",
    async () => {
      // keep the live data loop going even if the server is not connected but return
      // empty data without making the requests to the server.
      if (!systemId || !headersWithAuth) {
        return {
          systemStatus: undefined,
          wheelsLiveCounts: undefined,
          frontEndState: undefined,
        };
      }

      // TODO: Break these out into two try blocks so both of the calls are always sent to the server.
      //       The server triggers its processing off of receiving the wheel live counts data and this call may
      //       not reach the server if the system status is rejected quickly and both calls are then terminated.
      try {
        const { data: systemStatus } = await axios.get<SystemStatusData>(getSystemStatusURL(systemId), headersWithAuth);
        const { data: wheelsLiveCounts } = await axios.get<WheelLiveCountsData[] | WheelLiveCountsMessage>(
          getLiveCountsURL(systemId),
          headersWithAuth
        );
        //DM changed get to post
        const { data: frontEndState } = await axios.post<FrontEndData>(getFrontEndStateURL(), { seedSensingSystemId: systemId }, headersWithAuth);
        return {
          systemStatus,
          wheelsLiveCounts,
          frontEndState,
        };
      }
      catch (e) {
        return {
          systemStatus: undefined,
          wheelsLiveCounts: { message: "UNKNOWN_SERVER_ERROR" }, // translated below when displayed to user
          frontEndState: undefined,
        };
      }
    },
    { refetchInterval: 1000 }
  );

  if (!homePageServerData || !homePageServerData.wheelsLiveCounts) {
    return (//DM April 16 2024. Added hideAlarmButton={true} to hide Alarm History button for release. Remove hideAlarmButton={true} when ready to release Alarm History
      <Page name={HEADER_NAME} isAuthenticating={isAuthenticating} hideAlarmButton={false}>
        <h1 className="text-center">Loading...</h1>
      </Page>
    );
  }

  const activePlanting = true;
  const { systemStatus, wheelsLiveCounts, frontEndState } = homePageServerData as HomePageServerData;
  //const frontEndState = frontEndStateData as FrontEndStateData;
  const gpsSpeed = systemStatus?.currentSpeedMph ? systemStatus.currentSpeedMph.toFixed(2) : "-";
  const averageSpacing = systemStatus?.averageSpacingInches ? systemStatus.averageSpacingInches.toFixed(2) : "-";
  const targetSpacing = systemStatus?.targetSpacing ? systemStatus.targetSpacing.toFixed(2) : "-";
  const acres = systemStatus?.acreage ? systemStatus.acreage.toFixed(2) : "-";
  const cwtPerAcre = systemStatus?.cWeightPerAcre ? systemStatus.cWeightPerAcre.toFixed(2) : "-";
  //const sTemperature = systemStatus?.soilTemperature ? systemStatus.soilTemperature.toFixed(1): "-";//DM Jan 19 2024 removed totalCwt added soilTemperture
  const sTemperature = systemStatus?.soilTemperature.toString();//DM Jan 19 2024 removed totalCwt added soilTemperture

  //DM February 2 check if frontEndState.dataRowsInfo is populated. If not assign default data row
  let dataRowsInfo: DataRowInfo[] = frontEndState?.dataRowsInfo as any;
  if (dataRowsInfo === undefined || dataRowsInfo.length === 0) {
    dataRowsInfo = DEFAULT_FIRST_DATA_ROWS_INFO;
  }
  const visibleDataRowsConfig: DataRowInfo[] = dataRowsInfo.filter((info) => info.checked)
  //const visibleDataRowsConfig: DataRowInfo[] = dataRowsInfo.length > 0? dataRowsInfo.filter((info) => info.checked): DEFAULT_FIRST_DATA_ROWS_INFO.filter((info) => info.checked);
  //DM February 1 used to show percent or count data point
  var missesIndex = 0;
  var doublesIndex = 0;
  var idealIndex = 0;
  var notIdealIndex = 0;
  for (let index = 0; index < visibleDataRowsConfig.length; index++) {
    if (visibleDataRowsConfig[index].primary === "Misses") {
      missesIndex = index;
    }
    if (visibleDataRowsConfig[index].primary === "Doubles") {
      doublesIndex = index;
    }
    if (visibleDataRowsConfig[index].primary === "Ideal") {
      idealIndex = index;
    }
    if (visibleDataRowsConfig[index].primary === "Not Ideal") {
      notIdealIndex = index;
    }

  }
  const topRowHeightStyles = {
    height: `calc(var(--child-container-height) * ${TOP_ROW_HEIGHT_SCREEN_PERCENTAGE} / 100.0`,
  };
  const dataRowsHeight = visibleDataRowsConfig.length * DATA_ROW_HEIGHT_SCREEN_PERCENTAGE;
  const dataRowHeightStyles = { height: `calc(var(--child-container-height) * ${dataRowsHeight} / 100.0` };
  const middleRowHeight = 100 - TOP_ROW_HEIGHT_SCREEN_PERCENTAGE - dataRowsHeight;
  const middleRowHeightStyles = { height: `calc(var(--child-container-height) * ${middleRowHeight} / 100.0` };

  // if there is an error returned then display the error.
  if ((wheelsLiveCounts as WheelLiveCountsMessage).message) {
    let errorMsg = "Server Error";

    switch ((wheelsLiveCounts as WheelLiveCountsMessage).message) {
      case "PLANTING_INACTIVE": {
        errorMsg = "Planting is inactive";
        break;
      }

      case "SYSTEM_NOT_RESPONDING": {
        errorMsg = "Server not responding.";
        break;
      }

      case "UNKNOWN_SERVER_ERROR": {
        errorMsg = "Server error retrieving live data.";
      }
    }
    return (//DM April 16 2024. Added hideAlarmButton={true} to hide Alarm History button for release. Remove hideAlarmButton={true} when ready to release Alarm History
      <Page name={HEADER_NAME} isAuthenticating={isAuthenticating} hideAlarmButton={true}>
        <h1 className="text-center">{errorMsg}</h1>
      </Page>
    );
  }

  // divide the charting area into an equal number of columns based on the number of wheels
  const numWheels = (wheelsLiveCounts && (wheelsLiveCounts as WheelLiveCountsData[]).length) || 1;
  const columnsStyles = { display: "grid", gridTemplateColumns: `repeat(${numWheels}, 1fr)` };

  return (//DM April 16 2024. Added hideAlarmButton={true} to hide Alarm History button for release. Remove hideAlarmButton={true} when ready to release Alarm History
    <Page name={HEADER_NAME} isAuthenticating={isAuthenticating} hideAlarmButton={false}>
      <div className="live-container">
        <div className="my-container">
          <div className="row p-0">
            {/* left hand column */}
            <div className="col h-100 width-lh-col p-0 m-0">
              {/* logo in the top-left corner */}
              <div
                className="row p-0 w-100 m-0 justify-content-center align-items-center"
                style={topRowHeightStyles}
              ></div>

              {/* scale lines */}
              <div className="row p-0 m-0 d-flex" style={middleRowHeightStyles}>
                <ScaleComponent
                  numDisplayedTicksInclusive={NUM_DISPLAYED_BAR_TICKS_INCLUSIVE}
                  minDisplayedPercentage={MIN_DISPLAYED_BAR_PERCENTAGE}
                />
              </div>

              {/* labels for the data items */}
              <div className="row p-0" style={dataRowHeightStyles}>
                <ChartColumnDataTitlesComponent visibleDataRowsConfig={visibleDataRowsConfig} />
              </div>
            </div>

            {/* middle column with bar graphs */}
            <div className="width-center-col h-100">
              {/* individual wheel column of information including bar graph */}
              <div className="row" style={columnsStyles}>
                {wheelsLiveCounts &&
                  typeof wheelsLiveCounts === "object" &&
                  (wheelsLiveCounts as WheelLiveCountsData[])
                    .sort((a, b) => a?.wheelNumber - b?.wheelNumber)
                    .map((wheelLiveCounts: WheelLiveCountsData) => {
                      const {
                        status,
                        wheelNumber,
                        wheelSpeed,
                        wheelPressure,
                        batteryStateOfCharge,
                        misses,
                        missesPercent,
                        doubles,
                        doublesPercent,
                        ideal,
                        idealPercent,
                        notIdeal,
                        notIdealPercent,
                        successPercent,
                        //successPercentRow,//can remove?
                        wheelAverageSpacing,//DM added
                        wheelSpeedAlarmLevel, //DM Jan 21 24 PP.58
                        skipAlarmLevel, //DM Jan 21 24 PP.58
                      } = wheelLiveCounts || {};

                      //DM April 14. Calculate Success Percent based on selected data points
                      function calculateSuccessPercent() {
                        //DM April 15 2024. If ideal is 0 set calcSuccessPercent = 0. This could just be an issue in the dev environment. This should avoid NAN displayed.
                        var calcSuccessPercent = 0;
                        if (ideal !== 0) {
                          calcSuccessPercent = (ideal / (getPlantingSum())) * 100;
                        }
                        //var calcSuccessPercent = (idealCount / (idealCount + notIdealCount + missesCount + doublesCount)) * 100;

                        return calcSuccessPercent;
                      }

                      //DM May 2 2024 get sum of currently selected data points.
                      //If data point is not checked for visible set it to 0
                      function getPlantingSum() {
                        var notIdealCount = 0;
                        var missesCount = 0;
                        var doublesCount = 0;
                        for (let index = 0; index < visibleDataRowsConfig.length; index++) {
                          if (visibleDataRowsConfig[index].primary === "Misses") {
                            missesCount = misses;
                          }
                          if (visibleDataRowsConfig[index].primary === "Doubles") {
                            doublesCount = doubles;
                          }
                          if (visibleDataRowsConfig[index].primary === "Not Ideal") {
                            notIdealCount = notIdeal;
                          }
                        }
                        /*var idealCount = 1;
                        if(ideal !== 0){
                          idealCount = ideal;
                        }*/
                        var plantingSum = ideal + missesCount + doublesCount + notIdealCount;
                        return plantingSum;
                      }

                      //DM May 2 2024. Calculate percent for each data point based off the selected data points sum. No longer coming from back end.
                      function calcDataPointPercent(dataPointVavlue: number, visible: boolean) {
                        var calcPercent = 0;
                        if (visible && dataPointVavlue !== 0) {
                          calcPercent = (dataPointVavlue / getPlantingSum()) * 100;
                        }
                        return Math.round(calcPercent) + "%";
                      }

                      return (
                        <div key={wheelNumber}>
                          {/* top row of information (in a bordered box) for each wheel */}
                          <div className="row pt-2" style={topRowHeightStyles}>
                            <ChartColumnTopComponent
                              enabled={status !== 0}
                              PSI={`${wheelPressure}`}
                              batteryLevel={`${batteryStateOfCharge}`}
                            />
                          </div>

                          {/* bar chart for each wheel */}
                          <div className={"row p-2 border " + ((wheelSpeedAlarmLevel === 2 && "bg-danger") || (wheelSpeedAlarmLevel === 1 && "bg-warning") || (skipAlarmLevel === 1 && "bg-danger"))}//DM Jan 21 24 added alarm for stage 1. Jan 21 24 added skipAlarmLevel condition PP.58
                            style={middleRowHeightStyles}>
                            <ChartColumnBarComponent
                              active={!!activePlanting}
                              percentage={Math.round(calculateSuccessPercent())}//DM April 14 removed successPercent
                              scalePercentage={sliderValue}
                              warningRange={BAR_CHART_PERCENTAGE_WARNING_RANGE}
                              minBarHeightPercentage={BAR_CHART_MIN_BAR_HEIGHT_PERCENTAGE}
                              minDisplayedPercentage={MIN_DISPLAYED_BAR_PERCENTAGE}
                            />
                          </div>

                          {/* data items for each wheel. DM removed % Ideal added avgSpacing 
                            DM February 1 replaced
                            misses={`${misses}`}
                            doubles={`${doubles}`}
                            notIdeal={`${notIdeal}`}
                            ideal={`${ideal}`}
                          */}
                          <div className="row p-0 m-0" style={dataRowHeightStyles}>
                            <ChartColumnDataComponent
                              visibleDataRowsConfig={visibleDataRowsConfig}
                              speed={`${wheelSpeed}`}
                              misses={visibleDataRowsConfig[missesIndex].showPercent ? `${calcDataPointPercent(misses, visibleDataRowsConfig[missesIndex].checked) || 0}` : `${misses || 0}`}
                              doubles={visibleDataRowsConfig[doublesIndex].showPercent ? `${calcDataPointPercent(doubles, visibleDataRowsConfig[doublesIndex].checked) || 0}` : `${doubles || 0}`}
                              notIdeal={visibleDataRowsConfig[notIdealIndex].showPercent ? `${calcDataPointPercent(notIdeal, visibleDataRowsConfig[notIdealIndex].checked) || 0}` : `${notIdeal || 0}`}
                              ideal={visibleDataRowsConfig[idealIndex].showPercent ? `${calcDataPointPercent(ideal, visibleDataRowsConfig[idealIndex].checked) || 0}` : `${ideal || 0}`}
                              wheelAverageSpacing={`${wheelAverageSpacing}`}
                              wheelAlarmLevel={wheelSpeedAlarmLevel || 0} //DM Jan 21 24 PP.58
                              skipAlarmLevel={skipAlarmLevel || 0} //DM Jan 21 24 PP.58
                            />
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>

            {/* right hand column with cards */}
            <div className="p-0 m-0 width-rh-col">
              <div className="row w-100 p-0 m-0">
                <CardComponent header={t("liveGpsSpeedHeader")} text={gpsSpeed} />
                <CardComponent header={t("liveaverageSpacingHeader")} text={averageSpacing} />
                <CardComponent header={t("liveTargetSpacingHeader")} text={targetSpacing} />
                <CardComponent header={t("liveCwtPerAcreHeader")} text={cwtPerAcre} />
                <CardComponent header={t("liveSoilTemperature")} text={sTemperature} />
                <CardComponent header={t("liveAcresHeader")} text={acres} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Live;
