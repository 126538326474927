import { useContext, useEffect, useMemo, useState } from "react";
import {  useNavigate } from "react-router-dom";
import MUIDataTable, { FilterType, MUIDataTableColumn, Responsive, SelectableRows } from "mui-datatables";
import { Button } from "@mui/material";
import { formatTime } from "../../utils";
import config, { DisplayablePlantingData, CsvDownloadUrl } from "../../config";
import "./index.css";
import axios from "axios";
import downloadImage from "../../assets/images/download-icon.png";
import FrontEndContext from "../../context/FrontEndContext";

const { getCSVPlantingExport } = config?.api;

const CONFIG_STORAGE_KEY = "all-plantings";

interface FieldLogListProps {
  dataLoaded: boolean;
  activePlantings?: DisplayablePlantingData[];
  openPlantings?: DisplayablePlantingData[];
  closedPlantings?: DisplayablePlantingData[];
  headersWithAuth?: any;
}

export default function FieldLogList(props: FieldLogListProps) {
  const { dataLoaded, activePlantings, openPlantings, closedPlantings, headersWithAuth } = props;
  const navigate = useNavigate();
  const [plantings, setPlantings] = useState<DisplayablePlantingData[]>([]);
  const { t, language } = useContext(FrontEndContext);

  const clearLocalStorageConfig = () => {
    localStorage.removeItem(CONFIG_STORAGE_KEY);
  };

  useEffect(() => {
    clearLocalStorageConfig();
  }, [language]);

  

  const downloadCSVFile = async () => {
    //debugger;
    try {
      //Get url to CSV file
      const { data: csvUrl}  = await axios.post<CsvDownloadUrl>(getCSVPlantingExport(), {}, headersWithAuth);
      
      //Get the csv file
      const response = await axios.get(csvUrl.logsUrl, {
        headers: headersWithAuth,
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `FieldLogs_ ${new Date().toISOString().replace(/[^0-9]/g, '').slice(0, -3)}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error(`Error downloading the file:`);
    }
    
    /*
    const link = document.createElement('a');
    link.href = csvUrl.logsUrl;
    
    link.download = 'Planting_Data.csv';

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();
    document.body.removeChild(link);
    //console.log("download csv logic goes here. " + csvUrl.logsUrl);
    */

  }


  const COLUMNS = useMemo<MUIDataTableColumn[]>(() => {
    const infoOptions = {
      setCellProps: () => ({
        align: "center" as const,
      }),
    };

    const viewOptions = {
      filter: false,
      viewColumns: false,
      customBodyRender: (value: any) => (
        <Button variant="contained" onClick={() => navigate(`/planting/${value}`)}>
          {t("view")}
        </Button>
      ),
    };

    const dateOptions = {
      customBodyRender: (value: any) => formatTime(value),
      setCellProps: () => ({
        align: "center" as const,
      }),
    };

    return [
      { label: " ", name: "systemAndPlantingId", options: viewOptions },
      { label: t("company").toUpperCase(), name: "companyName", options: { ...infoOptions, display: false } },
      { label: t("system").toUpperCase(), name: "systemName", options: infoOptions },
      { label: t("farm").toUpperCase(), name: "farm", options: infoOptions },
      { label: t("field").toUpperCase(), name: "field", options: infoOptions },
      { label: t("variety").toUpperCase(), name: "variety", options: infoOptions },
      { label: t("seedlot").toUpperCase(), name: "seedlot", options: infoOptions },
      { label: t("acreage").toUpperCase(), name: "acreage", options: infoOptions },
      { label: t("seedSpacing").toUpperCase(), name: "seedSpacing", options: infoOptions },
      { label: t("seedWeight").toUpperCase(), name: "seedWeight", options: infoOptions },
      { label: t("lastUpdated").toUpperCase(), name: "updateTime", options: dateOptions },
    ];
  }, [t, navigate])


  const OPTIONS = {
    download: false,
    filterType: "dropdown" as FilterType,
    pagination: true,
    rowsPerPageOptions: [10, 25, 100],
    print: false,
    customToolbar: () => (<img className="MuiButtonBase-root tss-10rusft-MUIDataTableToolbar-icon MuiIconButton-root MuiIconButton-sizeMedium css-78trlr-MuiButtonBase-root-MuiIconButton-root" 
        onClick={downloadCSVFile} data-testid="DownloadCSV-iconButton" aria-label="Download CSV" src={downloadImage} width="40px" height="40px">
      
    </img>),
    responsive: "standard" as Responsive,//DM April 1 changed vertical to scroll
    selectableRows: "single" as SelectableRows,
    selectableRowsHideCheckboxes: true,
    sortOrder: {
      name: "updateTime",
      direction: "desc" as any,
    },
    storageKey: CONFIG_STORAGE_KEY,
    textLabels: {
      body: {
        noMatch: dataLoaded ?t("noFieldLogsAvailable") :t("loading"),
      },
      pagination: {
        next: t("nextPage"),
        previous: t("previousPage"),
        rowsPerPage: t("rowsPerPage"),
        displayRows: t("displayRows"),
      },
      toolbar: {
        search: t("searchTable"),
        downloadCsv: t("downloadCsv"),
        viewColumns: t("viewColumns"),
        filterTable: t("filterTable"),
      },
      filter: {
        all: t("tableFilterAll"),
        title: t("tableFilterTitle"),
        reset: t("tableFilterReset"),
      },
      viewColumns: {
        title: t("viewColumnsTitle"),
        titleAria: t("viewColumnsTitleAria"),
      },
    },
  };

  useEffect(() => {
    if (!dataLoaded) {
      return;
    }

    const plantings: DisplayablePlantingData[] = [];

    if (activePlantings) {
      for (const planting of activePlantings) {
        // Make sure that the planting is not already in the existing list
        if (!plantings.find((existing) => existing.systemAndPlantingId === planting.systemAndPlantingId)) {
          plantings.push(planting);
        }
      }
    }

    if (openPlantings) {
      for (const planting of openPlantings) {
        // Make sure that the planting is not already in the existing list
        if (!plantings.find((existing) => existing.systemAndPlantingId === planting.systemAndPlantingId)) {
          plantings.push(planting);
        }
      }
    }

    if (closedPlantings) {
      for (const planting of closedPlantings) {
        // Make sure that the planting is not already in the existing list
        if (!plantings.find((existing) => existing.systemAndPlantingId === planting.systemAndPlantingId)) {
          plantings.push(planting);
        }
      }
    }

    setPlantings(plantings);
  }, [dataLoaded, activePlantings, closedPlantings, openPlantings]);

  return (
    <div className="field-log-table">
      <MUIDataTable title={t("fieldLogs")} data={plantings} columns={COLUMNS} options={OPTIONS} />
    </div>
  );
}
