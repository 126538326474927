import React, { useState, useContext, useMemo, useEffect } from "react";
import Select, { OptionsType } from "react-select";
import { XAxis, YAxis, CartesianGrid, ResponsiveContainer, Bar, BarChart, Legend } from "recharts";
import { COLOR_IDEAL, COLOR_NOT_IDEAL, COLOR_DOUBLES, COLOR_MISSES } from "../../colors";
import FrontEndContext from "../../context/FrontEndContext";

interface SelectionMetaData {
  value: string;
  label: string;
  color: string;
}

export default function PlantingSummaryGraph(props: { data: any[] }) {
  const { data } = props;
  const { t } = useContext(FrontEndContext);

  const options: SelectionMetaData[] = useMemo(() => [
    { value: "misses", label: t("misses"), color: COLOR_MISSES },
    { value: "doubles", label: t("doubles"), color: COLOR_DOUBLES },
    { value: "notIdeal", label: t("notIdeal"), color: COLOR_NOT_IDEAL },
    { value: "ideal", label: t("ideal"), color: COLOR_IDEAL },
  ], [t]);

  const [selectedOptions, setSelectedOptions] = useState<OptionsType<SelectionMetaData>>([]);

  useEffect(() => {
    setSelectedOptions(options);
  }, [options]);

  const selectedOptionsMap: { [id: string]: boolean } = {};
  for (const selectedOption of selectedOptions) {
    selectedOptionsMap[selectedOption.value] = true;
  }

  const translatedData = useMemo(() => {
    return data.map(item => ({
      ...item,
      [t("misses")]: item["Misses"],
      [t("doubles")]: item["Doubles"],
      [t("notIdeal")]: item["Not Ideal"],
      [t("ideal")]: item["Ideal"],
    }));
  }, [data, t]);

  return (
    <>
      <div style={{ width: "100%", height: 10 }}> </div>
      <Select
        options={options}
        isMulti={true}
        onChange={(values) => setSelectedOptions(values)}
        value={selectedOptions}
      />
      <div style={{ width: "100%", height: 10 }}> </div>

      <div style={{ width: "100%", height: 328 }}>
        <ResponsiveContainer>
          <BarChart
            data={translatedData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Legend verticalAlign="top" />
            {options.map((option) =>
              selectedOptionsMap[option.value] ? (
                <Bar key={`graph-bar-${option.value}`} dataKey={t(option.value)} fill={option.color} name={option.label} />
              ) : null
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}