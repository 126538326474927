import { useContext, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FrontEndContext from "../../context/FrontEndContext";
import bssiLogo from "../../assets/images/bssi_logo_light.png";
import "./index.css";
import { BsPersonCircle, BsPersonPlus } from "react-icons/bs";
import { Dropdown } from "react-bootstrap";
import { Button, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { SupportedLanguage } from "../../App";

export interface HeaderBarProps {
  name?: string;
  hideUserMenu?: boolean;
  hideAlarmButton?: boolean;
}

const HeaderBar = (props: HeaderBarProps) => {
  const navigate = useNavigate();
  //DM Aug 5 2024. When window is resized if width is less than 710 pixels remove username from drop down. Mainly for portrail mode on mobile phones.
  const useWindowSize = () => {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
      const updateSize = () => {
        setSize(window.innerWidth);
      };
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };
  
  const { name, hideUserMenu, hideAlarmButton } = props;

const { isAuthenticating, isAuthenticated, userName, doLogin, doLogout, systems,  language, setLanguage, t } = useContext(FrontEndContext);

  const windowWidth = useWindowSize();//DM Aug 5 2024 used in window resizing event.
  //const authText = isAuthenticating ? "Logging in..." : isAuthenticated ? userName : "Login";
  const authText = isAuthenticating ? "Logging in..." : isAuthenticated && windowWidth >= 710 ? userName : "";//DM Aug 5 2024 used in window resizing event.
  const logoStyle = { width: 24, height: 24 };
  const authLogo = isAuthenticated ? <BsPersonCircle style={logoStyle} /> : <BsPersonPlus style={logoStyle} />;

  const handleLanguageChange = (event: SelectChangeEvent<SupportedLanguage>) => {
    setLanguage(event.target.value as SupportedLanguage);
  };

  async function doChangePassword() {
    navigate("/change-password");
  }

  async function doGoToHomeScreen() {
    navigate("/");
  }

  

  const displayUserMenu = () => (
    <Dropdown align="end">
      <Dropdown.Toggle
        id="user-menu-dropdown-toggle"
        style={{
          backgroundColor: "white",
          borderColor: "rgba(0, 0, 0, .15)",
          borderRadius: "0px",
          boxShadow: "none",
          color: "black",
        }}
      >
        <span className="fs-6" style={{ display: "inline-flex", alignItems: "center" }}>
          {authText}&nbsp;&nbsp;{authLogo}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu
        id="user-menu-dropdown-menu-items"
        style={{ borderRadius: "0px", boxShadow: "5px 5px 5px gray", margin: "0px" }}
      >
        <Dropdown.Item as="button" onClick={doChangePassword}>
          Change Password
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as="button" onClick={doLogout}>
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const displayAlarmButton = () => (
    <Button color="error" variant="contained" onClick={() => navigate(`/alerts/${systems[0].systemId}`)}>
      {t("alarmHistory")}
    </Button>
  );

  const displayNotLoggedInText = () => (
    <div className="header-text auth-text" onClick={() => !isAuthenticating && doLogin()}>
      <span className="align-self-center fs-6">{authText}</span>&nbsp;&nbsp;{authLogo}
    </div>
  );

  

  const displayRightItems = () => (
    <>
    {!isAuthenticated ? displayNotLoggedInText() : displayUserMenu()}
    <Select
      value={language}
      onChange={handleLanguageChange}
      size="small"
      style={{ marginLeft: '10px' }}
    >
      <MenuItem value="en">English</MenuItem>
        <MenuItem value="fr">Français</MenuItem>
        <MenuItem value="es">Español</MenuItem>
        <MenuItem value="de">Deutsch</MenuItem>
        <MenuItem value="nl">Nederlands</MenuItem>
    </Select>
    </>
  );
 
  return (
    <div className="header-container">
      <div className="left-items">
        <div>
          <img className="bssi-logo" onClick={doGoToHomeScreen} src={bssiLogo} alt="BSSI logo" />
        </div>
      </div>
      <div className="mid-left-items">{!hideAlarmButton && displayAlarmButton()}
        
      </div>
      <div className="middle-items">
        <div className="header-text">{name || ""}</div>
      </div>
      <div className="right-items">{!hideUserMenu && displayRightItems()}</div>
    </div>
  );
};

export default HeaderBar;
