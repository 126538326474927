import React from "react";
import { DisplayAlarmList, DisplayablePlantingData } from "../config";
import { SupportedLanguage } from "../App";

export interface SystemInfo {
  systemId: string;
  systemName: string;
}

export interface FrontEndContextInterface {
  handleAuthentication: (userSession: any, error?: any) => void;
  setBearerToken: (token: string) => void;
  headersWithAuth: any;
  userName: string;
  setUserName: (userName: string) => void;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  doLogin: () => void;
  doLogout: () => void;
  systems: SystemInfo[];
  dataLoaded: boolean;
  activePlantings: DisplayablePlantingData[];
  openPlantings: DisplayablePlantingData[];
  closedPlantings: DisplayablePlantingData[];
  alarmList: DisplayAlarmList[];//DM added February 5. Show alerts
  language: SupportedLanguage;
  setLanguage: (lang: SupportedLanguage) => void;
  t: (key: string) => string;
}

const FrontEndContext = React.createContext<FrontEndContextInterface>({
  handleAuthentication: (userSession: any, error?: any) => { },
  setBearerToken: () => { },
  headersWithAuth: {},
  userName: "",
  setUserName: () => { },
  isAuthenticating: false,
  isAuthenticated: false,
  doLogin: () => { },
  doLogout: () => { },
  systems: [],
  dataLoaded: false,
  activePlantings: [],
  openPlantings: [],
  closedPlantings: [],
  alarmList: [],//DM added February 5. Show alerts
  language: 'de',
  setLanguage: () => { },
  t: (key: string) => key,
});

export default FrontEndContext;
