import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Auth } from "aws-amplify";
import axios, { AxiosError, AxiosResponse } from "axios";
import FrontEndContext, { FrontEndContextInterface, SystemInfo } from "./context/FrontEndContext";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import About from "./pages/About";
import Authentication from "./pages/Authentication";
import Planting from "./pages/Planting";
import Live from "./pages/Live";
import Alerts from "./pages/Alerts";//DM February 6 added to acces Alerts page
import config, { ApiPlantingData, DisplayablePlantingData, PlantingType, PLANTING_TYPE_NAMES, DisplayAlarmList } from "./config";

// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import { isTokenExpired, refreshBearerToken } from "./services/auth";

// Import MUI components and localization
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { enUS, frFR, esES, deDE, nlNL } from '@mui/material/locale';

const { SYSTEMS, API_FAIL_ERROR, ACTIVEPLANTING, OPENPLANTINGS, CLOSEDPLANTINGS } = config?.api;

const EMPTY_SYSTEM_INFO: SystemInfo = {
  systemId: "",
  systemName: "",
};

// Define supported languages
export type SupportedLanguage = 'en' | 'fr' | 'es' | 'de' | 'nl';

// MUI locale mapping
const muiLocales: Record<SupportedLanguage, any> = {
  en: enUS,
  fr: frFR,
  es: esES,
  de: deDE,
  nl: nlNL,
};


const translations: Record<SupportedLanguage, Record<string, string>> = {
  en: {
    loggingIn: "Logging in...",
    changePassword: "Change Password",
    logout: "Logout",
    alarmHistory: "Alarm History",
    view: "View",
    company: "Company",
    system: "System",
    farm: "Farm",
    field: "Field",
    variety: "Variety",
    seedlot: "Seedlot",
    acreage: "Acreage",
    seedSpacing: "Seed Spacing",
    seedWeight: "Seed Weight",
    lastUpdated: "Last Updated",
    downloadCSV: "Download CSV",
    noFieldLogsAvailable: "No field logs available.",
    loading: "Loading ...",
    fieldLogs: "Field Logs",
    misses: "Misses",
    doubles: "Doubles",
    notIdeal: "Not Ideal",
    ideal: "Ideal",
    percentIdeal: "% Ideal",
    rowsNumber: "Rows #",
    total: "Total",
    activePlantings: "Active Plantings",
    noActivePlantings: "No Active Plantings",
    wheelNumber: "Wheel Number",
    alarmType: "Alarm Type",
    alarmLevel: "Alarm Level",
    alarmTime: "Alarm Time",
    nextPage: "Next Page",
    previousPage: "Previous Page",
    rowsPerPage: "Rows per page:",
    displayRows: "of",
    searchTable: "Search",
    downloadCsv: "Download CSV",
    viewColumns: "View Columns",
    filterTable: "Filter Table",
    tableFilterAll: "All",
    tableFilterTitle: "FILTERS",
    tableFilterReset: "RESET",
    viewColumnsTitle: "Show Columns",
    viewColumnsTitleAria: "Show/Hide Table Columns",
    liveSpeed: "SpeedTest",
    liveMisses: "Misses",
    liveDoubles: "Doubles",
    liveNotIdeal: "Not Ideal",
    liveIdeal: "Ideal",
    liveWheelAverageSpacing: "Avg Spacing",
    liveGpsSpeedHeader: "GPS Speed Test",
    liveAverageSpacingHeader: "Avg. Spacing",
    liveTargetSpacingHeader: "Target Spacing",
    liveCwtPerAcreHeader: "Cwt/ac",
    liveSoilTemperature: "Temperature \xB0F",
    liveAcresHeader: "Acres",
  },
  fr: {
    loggingIn: "Connexion...",
    changePassword: "Changer le mot de passe",
    logout: "Se déconnecter",
    alarmHistory: "Historique des alertes",
    view: "Voir",
    company: "Entreprise",
    system: "Système",
    farm: "Ferme",
    field: "Champ",
    variety: "Variété",
    seedlot: "Lot de semences",
    acreage: "Superficie",
    seedSpacing: "Espacement des semences",
    seedWeight: "Poids des semences",
    lastUpdated: "Dernière mise à jour",
    downloadCSV: "Télécharger CSV",
    noFieldLogsAvailable: "Aucun journal de champ disponible.",
    loading: "Chargement...",
    fieldLogs: "Journaux de champ",
    misses: "Ratés",
    doubles: "Doubles",
    notIdeal: "Non idéal",
    ideal: "Idéal",
    percentIdeal: "% idéal",
    rowsNumber: "Rangs #",
    total: "Total",
    activePlantings: "Plantations actives",
    noActivePlantings: "Aucune plantation active",
    wheelNumber: "Numéro de Roue",
    alarmType: "Type d'alarme",
    alarmLevel: "Niveau d'alarme",
    alarmTime: "Heure de l'alarme",
    nextPage: "Page suivante",
    previousPage: "Page précédente",
    rowsPerPage: "Lignes par page:",
    displayRows: "de",
    searchTable: "Recherche",
    downloadCsv: "Télécharger CSV",
    viewColumns: "Afficher les colonnes",
    filterTable: "Filtrer le tableau",
    tableFilterAll: "TOUT",
    tableFilterTitle: "FILTRES",
    tableFilterReset: "RÉINITIALISER",
    viewColumnsTitle: "Afficher les colonnes",
    viewColumnsTitleAria: "Afficher/Masquer les colonnes du tableau",
    liveSpeed: "Speed",
    liveMisses: "Misses",
    liveDoubles: "Doubles",
    liveNotIdeal: "Not Ideal",
    liveIdeal: "Ideal",
    liveWheelAverageSpacing: "Avg Spacing",
    liveGpsSpeedHeader: "Vitesse GPS",
    liveAverageSpacingHeader: "Moy. Espacement",
    liveTargetSpacingHeader: "Espacement des cibles",
    liveCwtPerAcreHeader: "Cwt/ac",
    liveSoilTemperature: "Température \xB0F",
    liveAcresHeader: "Acres",
  },
  es: {
    loggingIn: "Iniciando sesión...",
    changePassword: "Cambiar contraseña",
    logout: "Cerrar sesión",
    alarmHistory: "Historial de alarmas",
    view: "Ver",
    company: "Empresa",
    system: "Sistema",
    farm: "Granja",
    field: "Campo",
    variety: "Variedad",
    seedlot: "Lote de semillas",
    acreage: "Área",
    seedSpacing: "Espaciado de semillas",
    seedWeight: "Peso de semillas",
    lastUpdated: "Última actualización",
    downloadCSV: "Descargar CSV",
    noFieldLogsAvailable: "No hay registros de campo disponibles.",
    loading: "Cargando...",
    fieldLogs: "Registros de campo",
    misses: "Fallos",
    doubles: "Dobles",
    notIdeal: "No ideal",
    ideal: "Ideal",
    percentIdeal: "% Ideal",
    rowsNumber: "Filas #",
    total: "Total",
    activePlantings: "Plantaciones activas",
    noActivePlantings: "No hay plantaciones activas",
    wheelNumber: "Número de rueda",
    alarmType: "Tipo de alarma",
    alarmLevel: "Nivel de alarma",
    alarmTime: "Hora de alarma",
    nextPage: "Página siguiente",
    previousPage: "Página anterior",
    rowsPerPage: "Filas por página:",
    displayRows: "de",
    searchTable: "Buscar",
    downloadCsv: "Descargar CSV",
    viewColumns: "Ver columnas",
    filterTable: "Tabla de filtros",
    tableFilterAll: "TODO",
    tableFilterTitle: "FILTROS",
    tableFilterReset: "REINICIAR",
    viewColumnsTitle: "Mostrar columnas",
    viewColumnsTitleAria: "Mostrar/ocultar columnas de tabla",
    liveSpeed: "Speed",
    liveMisses: "Misses",
    liveDoubles: "Doubles",
    liveNotIdeal: "Not Ideal",
    liveIdeal: "Ideal",
    liveWheelAverageSpacing: "Avg Spacing",
    liveGpsSpeedHeader: "Velocidad GPS",
    liveAverageSpacingHeader: "Promedio Espaciado",
    liveTargetSpacingHeader: "Espaciado objetivo",
    liveCwtPerAcreHeader: "Cwt/ac",
    liveSoilTemperature: "Temperatura \xB0F",
    liveAcresHeader: "Acres",
  },
  de: {
    loggingIn: "Anmelden...",
    changePassword: "Passwort ändern",
    logout: "Abmelden",
    alarmHistory: "Alarmverlauf",
    view: "Ansehen",
    company: "Firma",
    system: "System",
    farm: "Bauernhof",
    field: "Feld",
    variety: "Sorte",
    seedlot: "Saatgutpartie",
    acreage: "Flächeninhalt",
    seedSpacing: "Saat Abstand",
    seedWeight: "Saatgewicht",
    lastUpdated: "Zuletzt aktualisiert",
    downloadCSV: "CSV herunterladen",
    noFieldLogsAvailable: "Keine Feldprotokolle verfügbar.",
    loading: "Laden...",
    fieldLogs: "Feldprotokolle",
    misses: "Verfehlungen",
    doubles: "Doppelt",
    notIdeal: "Nicht ideal",
    ideal: "Ideal",
    percentIdeal: "% ideal",
    rowsNumber: "Reihen #",
    total: "Gesamt",
    activePlantings: "Aktive Anpflanzungen",
    noActivePlantings: "Keine aktiven Anpflanzungen",
    wheelNumber: "Radnummer",
    alarmType: "Alarmtyp",
    alarmLevel: "Alarmstufe",
    alarmTime: "Alarmzeit",
    nextPage: "Nächste Seite",
    previousPage: "Vorherige Seite",
    rowsPerPage: "Zeilen pro Seite:",
    displayRows: "von",
    searchTable: "Suchen",
    downloadCsv: "CSV herunterladen",
    viewColumns: "Spalten anzeigen",
    filterTable: "Filtertabelle",
    tableFilterAll: "Alle",
    tableFilterTitle: "FILTER",
    tableFilterReset: "ZURÜCKSETZEN",
    viewColumnsTitle: "Spalten anzeigen",
    viewColumnsTitleAria: "Tabellenspalten ein-/ausblenden",
    liveSpeed: "Speed",
    liveMisses: "Misses",
    liveDoubles: "Doubles",
    liveNotIdeal: "Not Ideal",
    liveIdeal: "Ideal",
    liveWheelAverageSpacing: "Avg Spacing",
    liveGpsSpeedHeader: "GPS-Geschwindigkeit",
    liveAverageSpacingHeader: "Durchschn Abstand",
    liveTargetSpacingHeader: "Zielabstand",
    liveCwtPerAcreHeader: "Cwt/ac",
    liveSoilTemperature: "Temperatur \xB0F",
    liveAcresHeader: "Hektar",
  },
  nl: {
    loggingIn: "Inloggen...",
    changePassword: "Wachtwoord wijzigen",
    logout: "Uitloggen",
    alarmHistory: "Alarmgeschiedenis",
    view: "Bekijken",
    company: "Bedrijf",
    system: "Systeem",
    farm: "Boerderij",
    field: "Veld",
    variety: "Variëteit",
    seedlot: "Zaadpartij",
    acreage: "Oppervlakte",
    seedSpacing: "Zaaiafstand",
    seedWeight: "Zaadgewicht",
    lastUpdated: "Laatst bijgewerkt",
    downloadCSV: "CSV downloaden",
    noFieldLogsAvailable: "Geen veldlogboeken beschikbaar.",
    loading: "Laden...",
    fieldLogs: "Veldlogboeken",
    misses: "Missers",
    doubles: "Dubbelen",
    notIdeal: "Niet ideaal",
    ideal: "Ideaal",
    percentIdeal: "% Ideaal",
    rowsNumber: "Rijen #",
    total: "Totaal",
    activePlantings: "Actieve beplantingen",
    noActivePlantings: "Geen actieve beplantingen",
    wheelNumber: "Wielnummer",
    alarmType: "Alarmtype",
    alarmLevel: "Alarmniveau",
    alarmTime: "Alarmtijd",
    nextPage: "Volgende pagina",
    previousPage: "Vorige pagina",
    rowsPerPage: "Rijen per pagina:",
    displayRows: "van",
    searchTable: "Zoekopdracht",
    downloadCsv: "CSV downloaden",
    viewColumns: "Kolommen bekijken",
    filterTable: "Filtertabel",
    tableFilterAll: "Alle",
    tableFilterTitle: "FILTERS",
    tableFilterReset: "RESET",
    viewColumnsTitle: "Kolommen weergeven",
    viewColumnsTitleAria: "Tabelkolommen tonen/verbergen",
    liveSpeed: "Speed",
    liveMisses: "Misses",
    liveDoubles: "Doubles",
    liveNotIdeal: "Not Ideal",
    liveIdeal: "Ideal",
    liveWheelAverageSpacing: "Avg Spacing",
    liveGpsSpeedHeader: "GPS-snelheid",
    liveAverageSpacingHeader: "Gem. Afstand",
    liveTargetSpacingHeader: "Doelafstand",
    liveCwtPerAcreHeader: "Cwt/ac",
    liveSoilTemperature: "Temperatuur \xB0F",
    liveAcresHeader: "Acres",
  }
};



function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [bearerToken, setBearerToken] = useState("");
  const [userName, setUserName] = useState<string>("");
  const [systems, setSystems] = useState<SystemInfo[]>([]);
  const [activePlantings, setActivePlantings] = useState<DisplayablePlantingData[]>([]);
  const [openPlantings, setOpenPlantings] = useState<DisplayablePlantingData[]>([]);
  const [closedPlantings, setClosedPlantings] = useState<DisplayablePlantingData[]>([]);
  const [plantingsRequested, setPlantingsRequested] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [alarmList, setAlarmList] = useState<DisplayAlarmList[]>([]);//DM added February 5. Show alerts

  const [language, setLanguage] = useState<SupportedLanguage>('en');

  const theme = useMemo(
    () =>
      createTheme(
        {
          // Your theme options
        },
        muiLocales[language]
      ),
    [language]
  );

  const t = (key: string) => translations[language][key] || key;

  const navigate = useNavigate();

  const headersWithAuth: any = useMemo(() => {
    if (!bearerToken) {
      return undefined;
    }
    return { headers: { Authorization: `Bearer ${bearerToken}` } };
  }, [bearerToken]);

  const resetApp = () => {
    setIsAuthenticated(false);
    setIsAuthenticating(false);
    setBearerToken("");
    setSystems([]);
    setActivePlantings([]);
    setOpenPlantings([]);
    setClosedPlantings([]);
    setPlantingsRequested(false);
  };

  const handleAuthentication = useCallback(
    async (userSession: any, error?: any) => {

      if (userSession) {
        setIsAuthenticated(true);
        if (userSession && userSession.idToken && userSession.idToken.payload) {
          const idToken = userSession && userSession.idToken && userSession.idToken.payload;
          if (idToken) {
            const { "cognito:username": cognitoUserName } = idToken;
            setUserName(cognitoUserName);
          }
        }
        let currentToken = userSession.getIdToken().getJwtToken();
        if (isTokenExpired(currentToken)) {
          currentToken = await refreshBearerToken();
        }

        setBearerToken(currentToken);
      } else {
        // When not logged in, reset the app
        resetApp();

        // No user is signed in. This is not an error but is returned as an exception.
        if (error !== "No current user") {
          // If Cognito returns that the user must reset their password, we force navigation to the
          // ConfirmationCode flow to have the user request a confirmation code from Cognito and then set
          // a new password with the code.
          if (error.message && error.message.includes("Password reset required for the user")) {
            navigate("/confirmation-code");
          } else {
            setBearerToken("");
          }
        }
      }

      setIsAuthenticating(false);
    },
    [navigate]
  );

  useEffect(() => {
    async function onLoad() {
      try {
        const currentSession = await Auth.currentSession();
        handleAuthentication(currentSession);
      } catch (e) {
        handleAuthentication(null, e);
      }
    }

    onLoad();
  }, [handleAuthentication]);

  useEffect(() => {
    if (!isAuthenticated || !headersWithAuth) {
      return;
    }

    async function getSystems() {
      interface ApiSystemsData {
        systemId: string;
        systemName: string;
      }

      try {
        const systemsResponse = await axios.get<ApiSystemsData[]>(SYSTEMS, headersWithAuth);
        if (systemsResponse?.status >= 400) {
          console.log(`[Systems] ${API_FAIL_ERROR}${systemsResponse?.status}`);
        }

        const systemInfos: ApiSystemsData[] = systemsResponse?.data;
        if (systemInfos && systemInfos.length > 0) {
          setSystems(
            systemInfos.map((systemInfo) => ({
              ...EMPTY_SYSTEM_INFO,
              systemId: systemInfo.systemId,
              systemName: systemInfo.systemName,
            }))
          );
        } else {
          const errorMsg = "[Systems] No systems associated with this user.";
          console.log(errorMsg);
          alert(errorMsg);
          doLogout();
        }

        // no server access, so mark the server as being offline
      } catch (error) {
        console.log(`[Systems] ${API_FAIL_ERROR}${error}`);
      }
    }

    getSystems();
  }, [headersWithAuth, isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (systems.length === 0 || plantingsRequested) {
      return;
    }

    const convertToDisplayable = (
      systemId: string,
      systemName: string,
      planting: ApiPlantingData,
      type: PlantingType
    ): DisplayablePlantingData => {
      if (!planting) {
        return undefined as unknown as DisplayablePlantingData;
      }

      const { id, range, acreage, seedWeight, updateTime } = planting;
      // calculating the `id` field as a combination of the systemId and the id of the planting
      // separated by a slash is important as this value is used as the last part of the URL that
      // is used to access the planting or the live data.
      // This is also used to ensure that the table has a unique ID that encompasses the system Id
      // and the planting id. Just in case the planting id is the same on different systems.
      const systemAndPlantingId = `${systemId}/${id}`;
      return {
        systemId,
        systemAndPlantingId,
        id,
        companyName: planting.companyName,
        systemName,
        type: PLANTING_TYPE_NAMES[type],
        farm: planting.farmName,
        field: planting.fieldName,
        variety: planting.varietyName,
        seedlot: planting.seedlotName,
        range,
        acreage,
        seedSpacing: planting.space,
        seedWeight,
        updateTime,
      };
    };

    const getSystemPlantings = async (systemId: string, systemName: string) => {
      let systemActivePlantings: DisplayablePlantingData[] = [];
      let systemOpenPlantings: DisplayablePlantingData[] = [];
      let systemClosedPlantings: DisplayablePlantingData[] = [];

      try {
        const params = {
          seedSensingSystemId: systemId,
        };

        const active = await axios.post<ApiPlantingData[]>(ACTIVEPLANTING, params, headersWithAuth);
        const open = await axios.post<ApiPlantingData[]>(OPENPLANTINGS, params, headersWithAuth);
        const closed = await axios.post<ApiPlantingData[]>(CLOSEDPLANTINGS, params, headersWithAuth);

        const apiError = ([active, open, closed] as any as AxiosResponse[]).find((response) => response!.status >= 400);
        if (apiError) {
          console.log(`[Plantings] ${API_FAIL_ERROR}${apiError.status}`);
        }

        systemActivePlantings = active?.data
          ? active.data.map((planting) => convertToDisplayable(systemId, systemName, planting, PlantingType.Active))
          : [];

        systemOpenPlantings = open?.data
          ? open.data.map((planting) => convertToDisplayable(systemId, systemName, planting, PlantingType.Open))
          : [];

        systemClosedPlantings = closed?.data
          ? closed.data.map((planting) => convertToDisplayable(systemId, systemName, planting, PlantingType.Closed))
          : [];

        // no server access, so mark the server as being offline
      } catch (error) {
        console.log(`[Plantings] ${API_FAIL_ERROR}${error as AxiosError}`);

        // always return an object (even if it simply contains empty arrays)
      } finally {
        return { systemActivePlantings, systemOpenPlantings, systemClosedPlantings };
      }
    };

    const getAllPlantings = async () => {
      let allActivePlantings: DisplayablePlantingData[] = [];
      let allOpenPlantings: DisplayablePlantingData[] = [];
      let allClosedPlantings: DisplayablePlantingData[] = [];

      // request the plantings for each system and accrue them in the plantings arrays
      const plantingsPromises = systems.map(async (systemInfo) => {
        const plantings = await getSystemPlantings(systemInfo.systemId, systemInfo.systemName);
        allActivePlantings = allActivePlantings.concat(plantings.systemActivePlantings);
        allOpenPlantings = allOpenPlantings.concat(plantings.systemOpenPlantings);
        allClosedPlantings = allClosedPlantings.concat(plantings.systemClosedPlantings);
      });

      // Waiting for all the requests to complete allows us to optimize the number of times the
      // internal state is refreshed and screen is redrawn.
      await Promise.allSettled(plantingsPromises);

      setActivePlantings(allActivePlantings);
      setOpenPlantings(allOpenPlantings);
      setClosedPlantings(allClosedPlantings);

      setDataLoaded(true);
    };

    setPlantingsRequested(true);
    getAllPlantings();
  }, [activePlantings, closedPlantings, headersWithAuth, openPlantings, plantingsRequested, systems]);

  async function doLogin() {
    navigate("/login");
  }

  async function doLogout() {
    await Auth.signOut();
    resetApp();
    navigate("/login");
  }

  const queryClient = new QueryClient();

  const frontEndContext: FrontEndContextInterface = {
    setBearerToken,
    handleAuthentication,
    headersWithAuth,
    userName,
    setUserName,
    isAuthenticating,
    isAuthenticated,
    doLogin,
    doLogout,
    systems,
    dataLoaded,
    activePlantings,
    openPlantings,
    closedPlantings,
    alarmList,//DM added February 5. Show alerts
     language,
    setLanguage,
    t,
  };

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
        <FrontEndContext.Provider value={frontEndContext}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Authentication authenticationAction="Login" />} />
            <Route
              path="/change-password-with-code"
              element={<Authentication authenticationAction="ChangePasswordWithCode" />}
            />
            <Route path="/change-password" element={<Authentication authenticationAction="ChangePassword" />} />
            <Route path="/confirmation-code" element={<Authentication authenticationAction="ConfirmationCode" />} />
            <Route
              path="/force-reset-password"
              element={<Authentication authenticationAction="ForceResetPassword" />}
            />
            <Route path="/about" element={<About />} />
            <Route path="/planting/:systemId/:id" element={<Planting />} />
            <Route path="/live/:systemId" element={<Live />} />
            <Route path="/alerts/:systemId" element={<Alerts />} />
            <Route path="/lost" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </FrontEndContext.Provider>
        </ThemeProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

export default App;
